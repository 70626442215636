function App() {
  const today = new Date();

  return (
    <>
    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'100px'}}>
      <div style={{whiteSpace: 'pre-wrap', lineHeight: '33px', display:'flex', flexDirection:'column', fontSize:'20px', padding:'28px', border:'1.5px solid'}}>
        <div>{`순천 ${Number(today.getFullYear())-2003+1}년 경력의 \n수학과외 선생님이 만든 교재입니다.`}</div>
        <div>010-6634-1225</div>
        <a href="tel:010-6634-1225" style={{marginTop:'25px', alignSelf:'center'}}>전화걸기</a>
      </div>
    </div>
    </>
  );
}

export default App;
